<template>
  <div>
    <h2 class="mb-2">
      Comments
    </h2>

    <b-row
      v-if="loading"
      align-h="center"
    >
      <div

        class="loader"
      />
    </b-row>

    <div v-else>
      <div
        v-if="comments.length"
      >
        <b-card
          v-for="(comment,commentIndex) in comments"
          :key="comment.id"
        >
          <comment
            :comment="mapComment(comment)"
            :delete-comment-url="deleteCommentUrl"
            @deleteComment="deleteComment(comments,commentIndex)"
          >
            <template #replies>
              <div
                v-for="(reply,replyIndex) in comment.replies"
                :key="reply.id"
              >
                <comment
                  :comment="mapReply(reply)"
                  :delete-comment-url="generateDeleteReplyUrl(comment.id)"
                  class="mt-1"
                  @deleteComment="deleteComment(comment.replies,replyIndex)"
                />
              </div>
            </template>
          </comment>
        </b-card>
      </div>

      <b-card
        v-else
        class="text-center"
      >
        No Comments found
      </b-card>
    </div>
  </div>
</template>
<script>
import Comment from '@/common/components/Activities/Comment.vue'

export default {
  name: 'LiveComments',
  components: {
    Comment,
  },
  data() {
    return {
      comments: [],
      loading: true,
      deleteCommentUrl: `/internalops/live/${this.$route.params.sessionId}/comments/`,
    }
  },
  created() {
    this.getLiveComments()
  },
  methods: {
    deleteComment(commentsList, index) {
      commentsList.splice(index, 1)
    },
    getLiveComments() {
      this.$activities.get(`/internalops/live/${this.$route.params.id}`).then(res => {
        this.comments = res.data.data.comments
      }).finally(() => { this.loading = false })
    },
    generateDeleteReplyUrl(commentId) {
      return `/internalops/live/${this.$route.params.sessionId}/comments/${commentId}/reply/`
    },
    mapComment(comment) {
      return {
        username: `${comment.user.profile.first_name} ${comment.user.profile.last_name}`,
        comment: comment.body,
        created_at: comment.created_at,
        id: comment.id,
        likes: comment.likes,
        replies: comment.replies,
      }
    },
    mapReply(reply) {
      return {
        username: `${reply.user.profile.first_name} ${reply.user.profile.last_name}`,
        comment: reply.body,
        created_at: reply.created_at,
        id: reply.id,
        likes: reply.likes,
      }
    },
  },
}
</script>
<style lang="scss">

</style>
